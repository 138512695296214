import React from "react"
import Header from "../components/header"
import * as H from "../components/headings"
import * as C from "../components/containers"
import { Box, Input, Label, Button, Textarea } from "theme-ui"

export default props => (
  <div>
    <Header/>
    <C.centered>
      <H.h1>Contact</H.h1>
      <Box>
        <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
          <Label htmlFor="name">Your Name: </Label><Input mb={3} type="text" name="name"/>
          <Label htmlFor="email">Your Email: </Label><Input mb={3} type="email" name="email"/>
          <Label htmlFor="message">Message: </Label><Textarea mb={3} name="message"></Textarea>
          <Box mt={3}>
            <Button type="submit">
              Submit
            </Button>
          </Box>
          <input type="hidden" name="bot-field"/>
          <input type="hidden" name="form-name" value="contact"/>
        </form>
      </Box>
    </C.centered>
  </div>
);